var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand_rectangle"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"block-item"},[_c('a-icon',{staticClass:"block-icon",attrs:{"type":"clock-circle"}}),_c('div',[_c('span',[_vm._v(_vm._s(_vm.date))]),_c('span',[_vm._v(_vm._s(_vm.time))])])],1),(_vm.mapData)?_c('div',{staticClass:"top-item"},[_c('span',[_vm._v("正在直播门店")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.mapData.living_count)))])]):_vm._e(),(_vm.mapData)?_c('div',{staticClass:"top-item"},[_c('span',[_vm._v("直播门店")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.mapData.live_count)))])]):_vm._e(),(_vm.mapData)?_c('div',{staticClass:"top-item"},[_c('span',[_vm._v("累计观看")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.mapData.live_total_watch_count)))])]):_vm._e()]),_c('div',{staticClass:"right-list"},[_c('div',{staticClass:"right-list-info"},[_c('img',{attrs:{"src":require("@/assets/img/logo_bc.jpg"),"alt":""}}),_c('div',{staticClass:"right-list-info-right"},[_vm._m(0),_c('div',{staticClass:"right-list-info-right-code"},[_c('svg-icon',{staticClass:"live-icon",attrs:{"icon-class":_vm.mapData.live_online_dealer_info_pos &&
              _vm.mapData.live_online_dealer_info_pos.length > 0
                ? 'living'
                : 'living_no'}}),(
              _vm.mapData.live_online_dealer_info_pos &&
                _vm.mapData.live_online_dealer_info_pos.length > 0
            )?_c('span',[_vm._v("正在直播")]):_c('span',{staticStyle:{"color":"#9ca8ba"}},[_vm._v("未开播")])],1)])]),_c('div',{staticClass:"right-list-main"},[_c('div',{staticClass:"right-list-main-title"},[_c('div',[_c('svg-icon',{staticClass:"live-icon",attrs:{"icon-class":_vm.mapData.live_online_dealer_info_pos &&
              _vm.mapData.live_online_dealer_info_pos.length > 0
                ? 'living'
                : 'living_no'}}),_c('span',[_vm._v("正在直播排行")])],1),_c('svg-icon',{staticClass:"refresh-icon",attrs:{"icon-class":"refresh"},on:{"click":_vm.refreshClick}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.mapData &&
            _vm.mapData.live_online_dealer_info_pos &&
            _vm.mapData.live_online_dealer_info_pos.length > 0
        ),expression:"\n          mapData &&\n            mapData.live_online_dealer_info_pos &&\n            mapData.live_online_dealer_info_pos.length > 0\n        "}],ref:"rightList",staticClass:"right-list-main-list"},_vm._l((_vm.mapData.live_online_dealer_info_pos),function(item,index){return _c('div',{key:index,class:_vm.currentVideoLength == index
              ? 'right-list-main-list-item active'
              : 'right-list-main-list-item'},[_c('div',[_c('svg-icon',{staticClass:"fire-icon",attrs:{"icon-class":"fire"}}),_c('span',[_vm._v(_vm._s(item.dealer_name || "-"))])],1),_c('div',[_c('svg-icon',{staticClass:"watch-icon",attrs:{"icon-class":"eye3"}}),(_vm.currentTab == 'kuaishou')?_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(item.unify_online_cnt)))]):_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(item.unify_watch_cnt)))])],1)])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.mapData ||
            !_vm.mapData.live_online_dealer_info_pos ||
            _vm.mapData.live_online_dealer_info_pos.length == 0
        ),expression:"\n          !mapData ||\n            !mapData.live_online_dealer_info_pos ||\n            mapData.live_online_dealer_info_pos.length == 0\n        "}],staticClass:"empty-container"},[_c('Empty')],1),_c('div',{staticClass:"right-list-main-shadow"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.mapData &&
        _vm.mapData.live_online_dealer_info_pos &&
        _vm.mapData.live_online_dealer_info_pos.length > 0
    ),expression:"\n      mapData &&\n        mapData.live_online_dealer_info_pos &&\n        mapData.live_online_dealer_info_pos.length > 0\n    "}],staticClass:"bottom-list"},[_c('div',{staticClass:"bottom-list-button",on:{"click":function($event){return _vm.scrollBottomList('left')}}},[_c('svg-icon',{staticClass:"arrow-icon disable",attrs:{"icon-class":"arrow-left"}})],1),_c('div',{ref:"bottomList",staticClass:"bottom-list-main"},_vm._l((_vm.mapData.live_online_dealer_info_pos),function(item,index){return _c('div',{key:index,class:_vm.currentVideoLength == index
            ? 'bottom-list-main-item active'
            : 'bottom-list-main-item',on:{"click":function($event){return _vm.liveCardClick(item, index)}}},[_c('div',{staticClass:"cover-container"},[_c('div',{staticClass:"img-box"},[_c('WebpImage',{attrs:{"src":item.cover_url,"scale":'1-1',"width":'100%',"height":'100%',"radius":'0px'}})],1),_c('div',{staticClass:"shadow-container"},[_c('svg-icon',{staticClass:"watch-icon",attrs:{"icon-class":"eye2"}}),(_vm.currentTab == 'kuaishou')?_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(item.unify_online_cnt)))]):_c('span',[_vm._v(_vm._s(_vm._f("changeNum")(item.unify_watch_cnt)))])],1),_c('div',{staticClass:"active-container"},[_c('svg-icon',{staticClass:"live-icon",attrs:{"icon-class":"living2"}}),_c('span',[_vm._v("正在直播")])],1)])])}),0),_c('div',{staticClass:"bottom-list-button",on:{"click":function($event){return _vm.scrollBottomList('right')}}},[_c('svg-icon',{staticClass:"arrow-icon right",attrs:{"icon-class":"arrow-left"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.mapData &&
        _vm.mapData.live_online_dealer_info_pos &&
        _vm.mapData.live_online_dealer_info_pos.length > 0
    ),expression:"\n      mapData &&\n        mapData.live_online_dealer_info_pos &&\n        mapData.live_online_dealer_info_pos.length > 0\n    "}],staticClass:"number-container"},[_c('div',[_c('span',{staticClass:"label"},[_vm._v("累计观看人数")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.userCount)))])]),_c('div',[_c('span',{staticClass:"label"},[_vm._v("粉丝增长")]),_c('span',{staticClass:"number"},[_vm._v(_vm._s(_vm._f("changeNum")(_vm.addCount)))])])]),_c('div',{attrs:{"id":"map"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.mapData &&
        _vm.mapData.live_online_dealer_info_pos &&
        _vm.mapData.live_online_dealer_info_pos.length > 0
    ),expression:"\n      mapData &&\n        mapData.live_online_dealer_info_pos &&\n        mapData.live_online_dealer_info_pos.length > 0\n    "}],staticClass:"video-container"},[_c('div',{staticClass:"video-box"},[(_vm.videoSrc_264 || _vm.videoSrc_265)?_c('PlayerItem',{attrs:{"id":'player-box',"h264url":_vm.videoSrc_264,"h265url":_vm.videoSrc_265,"isShowTooltip":!_vm.videoSrc_264 && _vm.videoSrc_265 && _vm.playable265 == 1}}):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-list-info-right-name"},[_c('span',[_vm._v("梅赛德斯-奔驰")])])
}]

export { render, staticRenderFns }