/** 
 * scale: img长宽比例
 * data: {
 *     name: "天津"
 *     value: [117.4219, 39.4189, 42]
 * }
**/
export function areaVideo(scale, data, zoom = 1, currentMapIndex) {
    return {
        name: "直播视频",
        type: "custom", //配置显示方式为用户自定义
        coordinateSystem: "geo",
        zlevel: 100,
        itemStyle: {
            normal: {
                color: "#46bee9"
            }
        },
        renderItem: function (params, api) {
            //具体实现自定义图标的方法
            // TODO 过滤数据
            if (params.dataIndex == currentMapIndex) {
                let width = 40
                return {
                    type: "image",
                    style: {
                        image: data[params.dataIndex].avatar,
                        width: width,
                        height: width,
                        borderRadius: 100,
                        x:
                            api.coord([
                                data[params.dataIndex].value[0],
                                data[params.dataIndex].value[1]
                            ])[0] -
                            width / 2,
                        y:
                            api.coord([
                                data[params.dataIndex].value[0],
                                data[params.dataIndex].value[1]
                            ])[1] -
                            width * scale + 15
                    }
                };
            }
        },
        data: data
    }
}

/** 
 * img: 箭头链接
 * data: {
 *     name: "天津"
 *     value: [117.4219, 39.4189, 42]
 * }
**/
export function areaArrow(img, data, currentMapIndex) {
    return {
        name: "箭头",
        type: "custom", //配置显示方式为用户自定义
        coordinateSystem: "geo",
        zlevel: 100,
        itemStyle: {
            normal: {
                color: "#46bee9"
            }
        },
        renderItem: function (params, api) {
            //具体实现自定义图标的方法
            // TODO 过滤数据
            if (params.dataIndex == currentMapIndex) {
                return {
                    type: "image",
                    style: {
                        image: img,
                        width: 20,
                        height: 30,
                        borderDashOffset: 5,

                        x:
                            api.coord([
                                data[params.dataIndex].value[0],
                                data[params.dataIndex].value[1]
                            ])[0] - 10,
                        y:
                            api.coord([
                                data[params.dataIndex].value[0],
                                data[params.dataIndex].value[1]
                            ])[1] - 22
                    }
                };
            }
        },
        data: data
    }
}

export function areaNickname(scale, data, currentMapIndex) {
    return {
        name: "nickname",
        type: "custom", //配置显示方式为用户自定义
        coordinateSystem: "geo",
        zlevel: 100,
        itemStyle: {
            normal: {
                color: "#46bee9"
            }
        },
        renderItem: function (params, api) {
            //具体实现自定义图标的方法
            // TODO 过滤数据
            if (params.dataIndex == currentMapIndex) {
                let width = 40
                console.log(data[params.dataIndex])
                return {
                    type: "text",
                    style: {
                        text: data[params.dataIndex].nickname,
                        fill: '#FFC05C',
                        x:
                            api.coord([
                                data[params.dataIndex].value[0],
                                data[params.dataIndex].value[1]
                            ])[0] -
                            width / 2 + 50,
                        y:
                            api.coord([
                                data[params.dataIndex].value[0],
                                data[params.dataIndex].value[1]
                            ])[1] -
                            width * scale + 29
                    }
                };
            }
        },
        data: data
    }
}

/** 
 * scale: img长宽比例
 * data: {
 *     name: "天津"
 *     value: [117.4219, 39.4189, 42]
 * }
**/
export function videoBorder(echarts, scale, data, zoom = 1, currentMapIndex) {
    return {
        name: "border",
        type: "custom", //配置显示方式为用户自定义
        coordinateSystem: "geo",
        zlevel: 10,
        itemStyle: {
            normal: {
                color: "#46bee9"
            }
        },
        renderItem: function (params, api) {
            //具体实现自定义图标的方法
            // TODO 过滤数据
            if (params.dataIndex == currentMapIndex) {
                let width = data[params.dataIndex].value[2] * zoom > 100 ? 100 : data[params.dataIndex].value[2] * zoom < 50 ? 50 : data[params.dataIndex].value[2] * zoom

                return {
                    // 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
                    type: "rect",
                    // shape 属性描述了这个矩形的像素位置和大小。
                    // 其中特殊得用到了 echarts.graphic.clipRectByRect，意思是，
                    // 如果矩形超出了当前坐标系的包围盒，则剪裁这个矩形。
                    shape: echarts.graphic.clipRectByRect(
                        {
                            width: width + 6,
                            height:
                                width * scale + 6,
                            x:
                                api.coord([
                                    data[params.dataIndex].value[0],
                                    data[params.dataIndex].value[1]
                                ])[0] -
                                width / 2,
                            y:
                                api.coord([
                                    data[params.dataIndex].value[0],
                                    data[params.dataIndex].value[1]
                                ])[1] -
                                width * scale - 15
                        },
                        {
                            width: width + 10,
                            height:
                                width * scale + 6,
                            x:
                                api.coord([
                                    data[params.dataIndex].value[0],
                                    data[params.dataIndex].value[1]
                                ])[0] -
                                width / 2,
                            y:
                                api.coord([
                                    data[params.dataIndex].value[0],
                                    data[params.dataIndex].value[1]
                                ])[1] -
                                width * scale - 15
                        }
                    ),
                    // 用 api.style(...) 得到默认的样式设置。这个样式设置包含了
                    // option 中 itemStyle 的配置和视觉映射得到的颜色。
                    style: api.style({ fill: "#ffb85b" })
                };
            }
        },
        data: data
    }
}

/** 
 * data: {
 *     name: "天津"
 *     value: [117.4219, 39.4189, 42]
 * }
**/
export function symbol(data) {
    return {
        symbolSize: function (val) {
            let zoom = 0.5
            return 14
        },
        label: {
            normal: {
                formatter: "",
                position: "right",
                show: true
            },
            emphasis: {
                show: true
            }
        },
        itemStyle: {
            normal: {
                color: "#FFC05C"
            }
        },
        name: "light",
        type: "scatter",
        coordinateSystem: "geo",
        data: data
    }
}
