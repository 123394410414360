<template>
  <div class="brand_rectangle">
    <div class="top">
      <div class="block-item">
        <a-icon type="clock-circle" class="block-icon" />
        <div>
          <span>{{ date }}</span>
          <span>{{ time }}</span>
        </div>
      </div>
      <div class="top-item" v-if="mapData">
        <span>正在直播门店</span>
        <span class="num">{{ mapData.living_count | changeNum }}</span>
      </div>
      <div class="top-item" v-if="mapData">
        <span>直播门店</span>
        <span class="num">{{ mapData.live_count | changeNum }}</span>
      </div>
      <div class="top-item" v-if="mapData">
        <span>累计观看</span>
        <span class="num">{{
          mapData.live_total_watch_count | changeNum
        }}</span>
      </div>
      <!-- <div class="top-item" v-if="mapData">
        <span><span style="color: #ffca5c">今日</span>官方号观看</span>
        <span class="num">{{ 12321 | changeNum }}</span>
      </div>
      <div class="top-item" v-if="mapData">
        <span><span style="color: #ffca5c">今日</span>全矩阵观看</span>
        <span class="num">{{ 12321 | changeNum }}</span>
      </div> -->
      <!-- <div class="top-left">
        <a-icon
          type="clock-circle"
          style="color: #4da3fa; font-size: 38px; margin: 0 16px 0 0"
        />
        <div>
          <span style="color: #fff">{{ date }}</span>
          <br />
          <span style="color: #e34e3a">{{ time }}</span>
        </div>
      </div> -->
      <!-- <div class="top-refresh" @click="refreshClick">
        <img src="@/assets/img/refresh.png" class="default" width="40" alt="" />
        <img
          src="@/assets/img/refresh-active.png"
          class="active"
          width="40"
          alt=""
        />
      </div> -->
    </div>
    <div class="right-list">
      <div class="right-list-info">
        <img src="@/assets/img/logo_bc.jpg" alt="" />
        <div class="right-list-info-right">
          <div class="right-list-info-right-name">
            <span>梅赛德斯-奔驰</span>
          </div>
          <div class="right-list-info-right-code">
            <svg-icon
              :icon-class="
                mapData.live_online_dealer_info_pos &&
                mapData.live_online_dealer_info_pos.length > 0
                  ? 'living'
                  : 'living_no'
              "
              class="live-icon"
            />
            <span
              v-if="
                mapData.live_online_dealer_info_pos &&
                  mapData.live_online_dealer_info_pos.length > 0
              "
              >正在直播</span
            >
            <span v-else style="color: #9ca8ba">未开播</span>
          </div>
        </div>
      </div>
      <div class="right-list-main">
        <div class="right-list-main-title">
          <div>
            <svg-icon
              :icon-class="
                mapData.live_online_dealer_info_pos &&
                mapData.live_online_dealer_info_pos.length > 0
                  ? 'living'
                  : 'living_no'
              "
              class="live-icon"
            />
            <span>正在直播排行</span>
          </div>
          <svg-icon
            icon-class="refresh"
            class="refresh-icon"
            @click="refreshClick"
          />
        </div>
        <div
          class="right-list-main-list"
          ref="rightList"
          v-show="
            mapData &&
              mapData.live_online_dealer_info_pos &&
              mapData.live_online_dealer_info_pos.length > 0
          "
        >
          <div
            :class="
              currentVideoLength == index
                ? 'right-list-main-list-item active'
                : 'right-list-main-list-item'
            "
            v-for="(item, index) in mapData.live_online_dealer_info_pos"
            :key="index"
          >
            <div>
              <svg-icon icon-class="fire" class="fire-icon" />
              <span>{{ item.dealer_name || "-" }}</span>
            </div>
            <div>
              <svg-icon icon-class="eye3" class="watch-icon" />
              <span v-if="currentTab == 'kuaishou'">{{
                item.unify_online_cnt | changeNum
              }}</span>
              <span v-else>{{ item.unify_watch_cnt | changeNum }}</span>
            </div>
          </div>
        </div>
        <div
          class="empty-container"
          v-show="
            !mapData ||
              !mapData.live_online_dealer_info_pos ||
              mapData.live_online_dealer_info_pos.length == 0
          "
        >
          <Empty />
        </div>
        <div class="right-list-main-shadow"></div>
      </div>
    </div>
    <div
      class="bottom-list"
      v-show="
        mapData &&
          mapData.live_online_dealer_info_pos &&
          mapData.live_online_dealer_info_pos.length > 0
      "
    >
      <div class="bottom-list-button" @click="scrollBottomList('left')">
        <svg-icon icon-class="arrow-left" class="arrow-icon disable" />
      </div>
      <div class="bottom-list-main" ref="bottomList">
        <div
          :class="
            currentVideoLength == index
              ? 'bottom-list-main-item active'
              : 'bottom-list-main-item'
          "
          v-for="(item, index) in mapData.live_online_dealer_info_pos"
          :key="index"
          @click="liveCardClick(item, index)"
        >
          <div class="cover-container">
            <div class="img-box">
              <WebpImage
                :src="item.cover_url"
                :scale="'1-1'"
                :width="'100%'"
                :height="'100%'"
                :radius="'0px'"
              />
            </div>
            <div class="shadow-container">
              <svg-icon icon-class="eye2" class="watch-icon" />
              <span v-if="currentTab == 'kuaishou'">{{
                item.unify_online_cnt | changeNum
              }}</span>
              <span v-else>{{ item.unify_watch_cnt | changeNum }}</span>
            </div>
            <div class="active-container">
              <svg-icon icon-class="living2" class="live-icon" />
              <span>正在直播</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-list-button" @click="scrollBottomList('right')">
        <svg-icon icon-class="arrow-left" class="arrow-icon right" />
      </div>
    </div>
    <div
      class="number-container"
      v-show="
        mapData &&
          mapData.live_online_dealer_info_pos &&
          mapData.live_online_dealer_info_pos.length > 0
      "
    >
      <div>
        <span class="label">累计观看人数</span>
        <span class="number">{{ userCount | changeNum }}</span>
      </div>
      <div>
        <span class="label">粉丝增长</span>
        <span class="number">{{ addCount | changeNum }}</span>
      </div>
    </div>
    <div id="map"></div>
    <div
      class="video-container"
      v-show="
        mapData &&
          mapData.live_online_dealer_info_pos &&
          mapData.live_online_dealer_info_pos.length > 0
      "
    >
      <!-- 当前直播门店名字 -->
      <!-- <a-tooltip v-if="mapData && mapData.data.length > 0">
        <template slot="title">{{
          mapData.data[currentVideoLength].name || ""
        }}</template>

        <div class="video-name">
          {{ mapData.data[currentVideoLength].name || "" }}
        </div>
      </a-tooltip> -->
      <div class="video-box">
        <PlayerItem
          :id="'player-box'"
          v-if="videoSrc_264 || videoSrc_265"
          :h264url="videoSrc_264"
          :h265url="videoSrc_265"
          :isShowTooltip="!videoSrc_264 && videoSrc_265 && playable265 == 1"
        />
      </div>
      <!-- <div class="info-box">
        <a-button
          type="primary"
          v-if="currentTab != 'kuaishou'"
          round
          class="btn"
          >累计观看人数</a-button
        >
        <span class="number" v-if="currentTab != 'kuaishou'">{{
          this.userCount
        }}</span>
        <a-button type="primary" round class="btn">{{
          currentTab == "douyin" ? "粉丝新增" : "在线人数"
        }}</a-button>
        <span class="number">{{
          currentTab == "douyin"
            ? this.addCount
            : currentTab == "kuaishou"
            ? this.addCount_ks
            : currentTab == "dongchedi"
            ? this.addCount_dcd
            : "-"
        }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as vuex from "vuex";
import echarts from "echarts";
import china from "echarts/map/json/china.json";
import MqttRemoval from "@/utils/mqttRemoval";
import "flv.js/dist/flv.min.js";
import flvjs from "flv.js";
import Paho from "@/utils/mqttws31.min.js";
import "videojs-contrib-hls";
import Hls from "hls.js";
import { getPrincipalId, getUserId } from "@/utils/auth";
import WebpImage from "@/components/WebpImage/WebpImage";
import _http from "@/api/brand_rectangle";
import Empty from "@/components/Empty/Empty";
import PlayerItem from "@/components/PlayerItem/PlayerItem";

// series
import {
  symbol,
  areaVideo,
  areaNickname,
  areaArrow,
  videoBorder,
} from "./series/series.js";

const { mapState, mapMutations, mapActions } = vuex.createNamespacedHelpers(
  "brand_rectangle"
);
export default {
  name: "brand_rectangle",
  components: {
    WebpImage,
    Empty,
    PlayerItem,
  },
  data() {
    return {
      defaultAvatar: require("@/assets/default/default1-1.png"),
      tabData: [
        {
          label: "抖音",
          value: "douyin",
        },
        {
          label: "快手",
          value: "kuaishou",
        },
        {
          label: "懂车帝",
          value: "dongchedi",
        },
      ],
      currentTab: "douyin",
      regionId: undefined,
      hls: "",
      removal: null,
      currentVideoLength: 0,
      isClicked: false,
      videoSrc: "",
      userCount: 0,
      addCount: 0,
      addCount_ks: 0,
      addCount_dcd: 0,
      client: null, // 创建一个客户端实例
      date: moment().format("YYYY-MM-DD"), // 当前日期
      time: moment().format("HH:mm"), // 当前时间
      scale: 1, // 地图图片宽高比例
      progress: 0, // 进度条百分比0-100 number
      oldMqttData: null, // 上一个订阅的消息
      currentTimer: null, // 当前时间定时器
      currentMapIndex: -1,
      player: null,
      platformClick: false,
      refreshTimer: null, // 5分钟刷新直播列表定时器
      pageRouterTimer: null, // 30分钟页面跳转定时器
      videoSrc_264: "",
      videoSrc_265: "",
      playable265: 0,
    };
  },
  watch: {
    mapData() {
      this.currentVideoLength = 0;
      this.scrollBottomList("left", true);
      this.rightListScroll();
      this.isClicked = false;
      this.initChart();
      if (this.mapData.live_online_dealer_info_pos.length > 0) {
        let params = this.mapData.live_online_dealer_info_pos[
          this.currentVideoLength
        ];
        // 加载video
        let that = this;
        if (this.platformClick) {
          this.flv_destroy();
        }
        this.setNumber(
          this.mapData.live_online_dealer_info_pos[this.currentVideoLength]
        );
        this.getStream(params);
      } else {
        this.flv_destroy();
        this.addCount = "-";
        this.addCount_ks = "-";
        this.addCount_dcd = "-";
        this.userCount = "-";
        this.$message.warning("当前暂无直播，请稍后刷新页面重试");
      }
    },
    mqttConfigData(val) {
      // 清除之前的订阅
      if (this.client && this.oldMqttData) {
        this.client.unsubscribe(
          this.oldMqttData.clientSubscribe.live.bulletAndStatisticsTopic
        );
        this.client.disconnect(); // 断开连接
        this.client = null;
      }
      this.oldMqttData = this.mqttConfigData;
      this.removal = new MqttRemoval();
      this.MQTTconnect();
    },
  },
  computed: {
    ...mapState({
      areaList: (state) => state.areaList,
      mapData: (state) => state.mapData, // 地图数据
      mqttConfigData: (state) => state.mqttConfigData, // 昨日开播数据
      onlineCountData: (state) => state.onlineCountData, // 查询某天直播均值(左侧轮播)
      allCountData: (state) => state.allCountData, // 获取主播粉丝数 和 累计观看人数 (初始值)
    }),
  },
  async created() {
    this.setPageTimer();

    // await this.getAreaList();
    // console.log(this.areaList);
    // if (!this.areaList.region_list && this.areaList.region_list.length == 0) {
    //   this.$message.warning("当前账号未绑定大区，请联系管理员");
    //   this.changeState({ data: null, name: "mapData" });
    //   this.changeState({ data: [], name: "areaList" });
    //   return false;
    // }
    await this.getMapData({
      principal_id: getPrincipalId(),
      platform: this.currentTab,
    });
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.chart.resize();
      })();
    };

    this.setCurrentTimer();
  },
  methods: {
    ...mapActions([
      "getAreaList",
      "getMqttConfig",
      "addLiveRoom",
      "getMapData",
      "loadConfig",
      "loadAllCount",
      "loadOnlineCount",
    ]),
    ...mapMutations(["changeState"]),
    // hls.js test

    tabChange(val) {
      console.log(val);
      this.platformClick = true;
      this.currentTab = val;
      this.getMapData({
        principal_id: getPrincipalId(),
        platform: this.currentTab,
      });
    },
    videoPause() {
      if (this.hls) {
        console.log("暂停视频");
        this.$refs.video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
    setNumber(item) {
      this.userCount = 0;
      this.addCount = 0;
      this.addCount_ks = 0;
      // todoD
      if (this.currentTab == "douyin") {
        this.userCount = item.unify_watch_cnt || 0;
        this.addCount = item.unify_online_cnt || 0;
      } else if (this.currentTab == "kuaishou") {
        this.userCount = item.unify_watch_cnt || 0;
        this.addCount_ks = item.unify_online_cnt || 0;
      } else if (this.currentTab == "dongchedi") {
        this.userCount = item.unify_watch_cnt || 0;
        this.addCount_dcd = item.unify_online_cnt || 0;
      }
    },
    getStream(params) {
      this.videoSrc_264 = "";
      this.videoSrc_265 = "";
      this.playable265 = 0;
      this.$nextTick(() => {
        this.videoSrc_264 = this.returnNewUrl(params.play_url_flv264);
        this.videoSrc_265 = this.returnNewUrl(params.play_url_flv265);
        this.playable265 = params.playable265;
      });
      if (!params.play_url_flv264 && !params.play_url_flv265) {
        this.$message.warning("暂未获取到当前直播地址");
      }
      //   // 重新获取配置
      this.loadAllConfig();
      // }
    },
    // 获取mqtt配置 并且 添加直播监听-todoD
    loadAllConfig() {
      this.getMqttConfig({
        platform_type:
          this.currentTab == "douyin"
            ? "1"
            : this.currentTab == "kuaishou"
            ? "2"
            : "3",
        author_id: this.mapData.live_online_dealer_info_pos[
          this.currentVideoLength
        ].author_id,
        device_id: getUserId(),
      });
      this.addLiveRoom({
        platform_type:
          this.currentTab == "douyin"
            ? "1"
            : this.currentTab == "kuaishou"
            ? "2"
            : "3",
        room_id: this.mapData.live_online_dealer_info_pos[
          this.currentVideoLength
        ].room_id,
      });
    },

    // 地图加载
    initChart() {
      var scale = this.scale;
      this.chart = echarts.init(document.getElementById("map"));
      echarts.registerMap("china", china);

      var geoCoordMap = this.mapData.geoCoordMap;
      var data = this.mapData.data;
      data.forEach((item, index) => {
        item.authorId = this.mapData.live_online_dealer_info_pos[
          index
        ].author_id;
        item.roomId = this.mapData.live_online_dealer_info_pos[index].room_id;
        item.avatar =
          this.mapData.live_online_dealer_info_pos[index].adb_author_info
            .avatar || this.defaultAvatar;
        item.nickname =
          this.mapData.live_online_dealer_info_pos[index].adb_author_info
            .nickname || "-";
      });

      /*获取地图数据*/
      var mapName = "china";
      // mychart.showLoading();
      var mapFeatures = echarts.getMap(mapName).geoJson.features;
      // mychart.hideLoading();
      mapFeatures.forEach(function(v) {
        // 地区名称
        var name = v.properties.name;
        // 地区经纬度
        geoCoordMap[name] = v.properties.cp;
      });

      var convertData = function(data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              cover: data[i].cover_url,
              avatar: data[i].avatar,
              nickname: data[i].nickname,
              authorId: data[i].authorId,
              roomId: data[i].roomId,
              index: i,
            });
          }
        }
        return res;
      };

      var option = {
        backgroundColor: {
          type: "linear",
          x: 0,
          y: 0,
          x2: 1,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: "#052C80", // 0% 处的颜色
            },
            {
              offset: 1,
              color: "#031D5A", // 100% 处的颜色
            },
          ],
          globalCoord: false, // 缺省为 false
        },
        title: {
          top: 20,
          text: "",
          subtext: "",
          x: "center",
          textStyle: {
            color: "#ccc",
          },
        },

        // tooltip: {
        //   trigger: "item",
        //   formatter: function (params) {
        //     if (typeof params.value[2] == "undefined") {
        //       return params.name + " : " + params.value;
        //     } else {
        //       return params.name + " : " + params.value[2];
        //     }
        //   },
        //   backgroundColor: "rgba(37, 50, 85, 0.9)",
        //   textStyle: {
        //     color: "#fff",
        //   },
        // },
        visualMap: {
          show: false,
          min: 0,
          max: 500,
          left: "left",
          top: "bottom",
          text: ["高", "低"], // 文本，默认为数值文本
          calculable: true,
          seriesIndex: [1],
          inRange: {},
        },
        geo: {
          map: "china",
          show: true,
          roam: false,
          zoom: 1,
          center: [113.114129, 33.550339],
          label: {
            normal: {
              show: false,
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#3A7FD5",
              borderColor: "#5BA4FF", //线
              shadowColor: "rgba(18, 59, 151, 0.5)", //外发光
              shadowBlur: 20,
            },
            emphasis: {
              areaColor: "#4794F4", //悬浮区背景
            },
          },
        },
        series: [
          // 标记
          symbol(convertData(data)),
          // 地图上的视频图片
          areaVideo(scale, convertData(data), 1, this.currentVideoLength),
          // 地图上的三角箭头
          areaNickname(scale, convertData(data), this.currentVideoLength),
          // 视频图片边框
          // videoBorder(
          //   echarts,
          //   scale,
          //   convertData(data),
          //   1,
          //   this.currentVideoLength
          // ),
        ],
      };
      this.chart.setOption(option);
    },
    // 当前时间更新
    setCurrentTimer() {
      this.currentTimer = setInterval(() => {
        this.date = moment().format("YYYY-MM-DD");
        this.time = moment().format("HH:mm");
      }, 1000);
    },
    // 底部列表点击滚动
    scrollBottomList(type, isFirst) {
      this.setRefreshTimer();
      console.log(dom);
      let dom = this.$refs[`bottomList`];
      console.log(dom);
      if (!dom) {
        return false;
      }
      let position = 0;
      if (type == "left") {
        position = dom.scrollLeft - 114 * 9;
      } else {
        position = dom.scrollLeft + 114 * 9;
      }
      if (isFirst) {
        position = 0;
      } else {
        console.log("scrollBottomList", "setPageTimer");
        this.setPageTimer();
      }
      dom.scrollTo({
        left: position,
        behavior: "smooth",
      });
    },
    // 直播卡片点击
    liveCardClick(item, index) {
      if (this.currentVideoLength == index) {
        return false;
      }
      this.setRefreshTimer();
      this.setPageTimer();
      console.log(item, index);
      this.currentVideoLength = index;
      this.rightListScroll();
      _http
        .getLiveDetail({
          room_id: item.room_id,
          author_id: item.author_id,
          platform: this.currentTab,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            if (res.data.data.finish === false) {
              let params = this.mapData.live_online_dealer_info_pos[
                this.currentVideoLength
              ];
              let that = this;
              this.flv_destroy();
              this.setNumber(
                this.mapData.live_online_dealer_info_pos[
                  this.currentVideoLength
                ]
              );
              this.initChart();
              this.getStream(params);
            } else {
              this.$message.warning("当前直播已结束，请换一场试试");
              this.refreshClick();
            }
          } else {
            this.$message.warning("当前直播已结束，请换一场试试");
            this.refreshClick();
          }
        });
    },
    returnNewUrl(url) {
      if (url) {
        try {
          let newUrl = url.replace(/^http/, "https");
          return newUrl;
        } catch (err) {
          // this.$message.warning(err);
        }
      } else {
        // this.$message.warning("未获取到当前直播地址");
      }
    },
    // 右上角列表滚动到指定位置
    rightListScroll() {
      let dom = this.$refs[`rightList`];
      dom.scrollTo({
        top: (this.currentVideoLength || 0) * 44,
        behavior: "smooth",
      });
    },
    MQTTconnect() {
      this.client = new Paho.MQTT.Client(
        this.mqttConfigData.endPoint,
        443,
        this.mqttConfigData.clientId
      );
      this.client.connect({
        userName: this.mqttConfigData.userName, //连接账号
        password: this.mqttConfigData.password, //密码
        cleanSession: true,
        onSuccess: this.onConnect,
        useSSL: true,
        onFailure: (message) => {
          setTimeout(this.MQTTconnect, 2000);
        },
        timeout: 3,
        reconnect: true,
        mqttVersion: 4,
      }); // 连接服务器并注册连接成功处理事件
      this.client.onConnectionLost = this.onConnectionLost; // 注册连接断开处理事件
      this.client.onMessageArrived = this.onMessageArrived; // 注册消息接收处理事件
    },

    //当客户端连接
    onConnect() {
      //建立连接后，进行订阅并发送消息。
      console.log(
        "onConnect",
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
      );
      this.client.subscribe(
        this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic,
        { qos: 0 }
      );
    },

    // 当客户端失去连接时调用
    onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
    },

    //当消息到达时
    onMessageArrived(message) {
      // console.log("onMessageArrived:", message);
      console.log("onMessageArrived:", JSON.parse(message));
      let newJson = JSON.parse(message.payloadString);
      // let bool = this.removal.isRemoval(newJson);
      let bool = true;
      if (bool && newJson.type == "2009") {
        this.userCount = newJson.data.accumulated_watching_count;
        // todoD
        if (this.currentTab == "douyin") {
          this.addCount = newJson.data.fans_change;
        } else {
          this.addCount = newJson.data.online_count;
        }
      } else if (newJson.type == "3001") {
        this.info();
      }
    },
    // 下播信息弹窗
    info() {
      const h = this.$createElement;
      this.$info({
        title: "当前直播已结束",
        content: h("div", {}, [
          h("p", "请切换其他直播或刷新页面获取最新正在直播。"),
        ]),
        onOk() {},
      });
    },
    // 刷新页面
    refreshClick() {
      // location.reload();

      this.setRefreshTimer();
      this.setPageTimer();
      this.getMapData({
        principal_id: getPrincipalId(),
        // region_id: this.regionId,
        platform: this.currentTab,
      });
    },
    flv_destroy() {},
    // 重置页面跳转定时器
    setPageTimer() {
      console.log("setPageTimer");
      clearTimeout(this.pageRouterTimer);
      this.pageRouterTimer = null;
      this.pageRouterTimer = setTimeout(() => {
        this.pageRouter();
      }, 1000 * 125);
    },
    // 重置页面直播列表刷新定时器
    setRefreshTimer() {
      console.log("setRefreshTimer");
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
      this.refreshTimer = setTimeout(() => {
        this.getMapData({
          principal_id: getPrincipalId(),
          platform: this.currentTab,
        });
      }, 1000 * 20);
    },
    // 定时路由跳转
    pageRouter() {
      this.flv_destroy();
      clearTimeout(this.pageRouterTimer);
      this.pageRouterTimer = null;
      clearTimeout(this.refreshTimer);
      this.refreshTimer = null;
      clearTimeout(this.currentTimer);
      this.currentTimer = null;
      this.$router.push(`/bcCoreScreen/brandCalendar`);
    },
  },
  beforeDestroy() {
    clearTimeout(this.pageRouterTimer);
    this.pageRouterTimer = null;
    clearTimeout(this.refreshTimer);
    this.refreshTimer = null;
    clearTimeout(this.currentTimer);
    this.currentTimer = null;
    // this.$notify.close();
    console.log("销毁");
    // if (this.client) {
    //   this.client.unsubscribe(
    //     this.mqttConfigData.clientSubscribe.live.bulletAndStatisticsTopic
    //   );
    //   this.client.disconnect(); // 断开连接
    //   this.client = null;
    // }
    // 销毁video实例
    // const videoDom = this.$refs.myVideo;
    // videojs(videoDom).dispose();
    this.flv_destroy();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_handle.scss";
.empty-container {
  width: 100%;
  height: calc(100% - 45px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.platform-div {
  width: 100%;
  height: 40px;
  @include background_color("background_color2");
  text-align: right;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  .beta-icon {
    width: 36px;
    height: 14px;
    border-radius: 7px;
    background: #ff4160;
    color: #fff;
    font-size: 10px;
    text-align: center;
    line-height: 14px;
    position: absolute;
    top: 4px;
    right: 0px;
  }
}
.brand_rectangle {
  width: 100%;
  height: 100vh;
  padding: 60px;
  background: #030e26;
  position: relative;

  .top {
    position: absolute;
    top: 90px;
    left: 90px;
    z-index: 99;
    width: 840px;
    height: 66px;
    display: flex;
    flex-direction: row;
    .block-item {
      width: 140px;
      height: 66px;
      padding: 12px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-right: 20px;
      .block-icon {
        color: #ffca5c;
        font-size: 32px;
        margin: 0 6px;
      }
      > div {
        width: calc(100% - 44px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        > span:first-child {
          color: #d0d6e6;
          font-size: 14px;
          font-weight: bold;
        }
        > span:last-child {
          font-size: 18px;
          color: #ffca5c;
          font-weight: bold;
        }
      }
    }
    &-item {
      padding: 14px 0 15px;
      width: 130px;
      height: 66px;
      margin-right: 10px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      > span:first-child {
        color: #9ca8ba;
        font-size: 12px;
      }
      > .num {
        font-size: 16px;
        color: #ffca5c;
        font-weight: bold;
      }
    }

    &-refresh {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 24px;
      cursor: pointer;
      .default {
        display: inline-block;
      }
      .active {
        display: none;
      }
    }
    &-refresh:hover {
      .default {
        display: none;
      }
      .active {
        display: inline-block;
      }
    }
  }
  .right-list {
    width: 290px;
    height: 354px;
    position: absolute;
    top: 90px;
    right: 90px;
    z-index: 200;
    &-info {
      width: 100%;
      height: 68px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      > img {
        width: 68px;
        height: 68px;
        border-radius: 16px;
        margin-right: 20px;
      }
      &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        &-name {
          display: flex;
          align-items: center;
          > span {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        &-code {
          display: flex;
          align-items: center;
          margin-top: 8px;

          .live-icon {
            font-size: 18px;
            margin-right: 11px;
          }
          span {
            font-size: 12px;
            color: #e75934;
          }
        }
      }
    }
    &-main {
      width: 290px;
      height: 256px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 16px;
      &-title {
        width: 100%;
        height: 44px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        .live-icon {
          font-size: 18px;
        }
        > div > span {
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          margin-left: 9px;
        }
        .refresh-icon {
          font-size: 16px;
        }
      }
      &-list {
        height: 210px;
        overflow-y: auto;
        &-item {
          width: 100%;
          height: 44px;
          color: #d0d6e6;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          &.active {
            color: #ffca5c;
          }
          &:last-child {
            margin-bottom: 25px;
          }
          > div {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
          }
          > div:first-child {
            width: 180px;
            height: 100%;
            .fire-icon {
              font-size: 16px;
              margin: 0 10px 0 20px;
            }
          }
          > div:last-child {
            width: 90px;
            height: 100%;
            .watch-icon {
              font-size: 22px;
              margin-right: 4px;
            }
          }
        }
      }
      &-shadow {
        width: 290px;
        height: 25px;
        background: linear-gradient(
          180deg,
          rgba(1, 18, 55, 0) 0%,
          #011237 100%
        );
        border-radius: 0 0 16px 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 201;
      }
    }
  }
  .bottom-list {
    width: 1254px;
    height: 194px;
    position: absolute;
    top: 795px;
    left: 90px;
    z-index: 200;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    &-button {
      width: 56px;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow-icon {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.8);
      }
      .arrow-icon.disable {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.4);
      }
      .arrow-icon.right {
        transform: rotate(180deg);
        -ms-transform: rotate(180deg); /* IE 9 */
        -moz-transform: rotate(180deg); /* Firefox */
        -webkit-transform: rotate(180deg); /* Safari 和 Chrome */
        -o-transform: rotate(180deg); /* Opera */
      }
    }
    &-main {
      width: 1102px;
      height: 220px;
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      margin: 0 20px;
      &-item {
        min-width: 110px;
        width: 110px;
        height: 195px;
        border-radius: 16px;
        margin-right: 14px;
        overflow: hidden;
        &.active {
          border: 2px solid #ffca5c;
          .cover-container {
            .active-container {
              width: 110px;
              height: 195px;
              background: rgba(0, 0, 0, 0.6);
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 10px 0 0 12px;
              z-index: 250;
              .live-icon {
                font-size: 10px;
                margin-right: 8px;
                margin-top: 4px;
              }
              span {
                font-size: 12px;
                color: #ffc05c;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0px;
        }

        .cover-container {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          > .img-box {
            position: absolute;
            left: -42.5px;
            top: 0;
            width: 195px;
            height: 195px;
            display: inline-block;
            z-index: 100;
          }
          .shadow-container {
            width: 110px;
            height: 36px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 0px 0px 16px 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            z-index: 300;
            .watch-icon {
              font-size: 22px;
              margin: 0 4px 0 10px;
            }
            span {
              font-size: 11px;
              font-weight: 500;
              color: #ffca5c;
            }
          }
          .active-container {
            display: none;
          }
        }
      }
    }
  }
  .number-container {
    width: 165px;
    height: 195px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 1365px;
    top: 795px;
    z-index: 300;
    > div {
      width: 100%;
      height: 97px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 26px 0 24px 27px;
      .label {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.6);
      }
      .number {
        font-weight: bold;
        color: #fff;
        font-size: 20px;
      }
      &:first-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.06);
      }
    }
  }

  #map {
    width: 100%;
    height: 960px;
    background: linear-gradient(90deg, #052c80 0%, #031d5a 100%);
    border-radius: 26px;
    overflow: hidden;
  }

  .video-container {
    width: 290px;
    height: 516px;
    position: absolute;
    right: 90px;
    top: 474px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    .video-box {
      width: 290px;
      height: 516px;
      border-radius: 16px;

      #my-video {
        width: 100% !important;
        height: 100% !important;
        video {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .info-box {
      width: 50%;
      min-width: 165px;
      height: 160px;
      border-radius: 10px;
      position: absolute;
      left: 80%;
      bottom: 25%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background: rgba(18, 58, 76, 0.7);
      padding: 20px;

      .btn {
        background: linear-gradient(270deg, #fbbc6c 0%, #f67c52 100%);
        border: none;
        color: #fff;
        width: 95%;
        font-size: 14px;
        height: 26px;
        padding: 0;
        text-align: center;
      }

      .number {
        font-size: 26px;
        font-weight: bold;
        color: #ff7544;
      }
    }
  }
}
.a-notification__title {
  color: #4b53fc !important;
}
.a-notification__content {
  color: #0a2250 !important;
}
</style>
